<template>
  <section class="w-full h-full md:grid md:place-content-center">
    <UILoading v-if="loading" />
    <div
      class="w-full md:w-[500px] bg-zinc-900 rounded-xl shadow-lg pt-12 pb-24 px-4 md:p-4 flex gap-4 flex-col justify-center items-center">
      <div class="flex items-center gap-2">
        <img src="/icon.png" class="h-[5rem] w-[5rem] rounded-full" alt="" @click="openPane({ type: 'Preference' })" />
      </div>
      <h3 class="text-white font-bold text-2xl">{{ type === 'login' ? $t('login.title') : $t('login.titleRegister') }}
      </h3>

      <div class="w-full flex items-center gap-2 p-2 rounded-2xl bg-input">
        <EnvelopeIcon class="flex-none size-7 text-zinc-300" />
        <UIInput type="text" :placeholder="$t('login.email')" v-model="email" />
      </div>

      <div class="w-full flex items-center gap-2 p-2 rounded-2xl bg-input">
        <LockClosedIcon class="flex-none size-7 text-zinc-300" />
        <UIInput type="password" :placeholder="$t('login.password')" v-model="password" />
      </div>

      <!-- agreement on register type -->
      <div class="w-full flex items-center gap-2" v-if="type === 'register'">
        <button class="w-full flex items-center gap-2 p-2 rounded-2xl bg-input default-transition" :class="{
          '!bg-green-500/20': agreeEula,
        }" @click="agreeEula = !agreeEula">
          <CheckIcon v-if="agreeEula" class="flex-none size-7 text-green-400" />
          <div v-else class="flex-none size-5 m-1 rounded-full bg-zinc-300/10" />
          <p>{{ $t('login.eula') }}</p>
        </button>
        <a href="/eula" target="_blank" class="flex-none grid place-content-center p-2 rounded-full bg-input">
          <EllipsisHorizontalCircleIcon class="flex-none size-7 text-zinc-300" />
        </a>
      </div>

      <!-- agreement on register type -->
      <div class="w-full flex items-center gap-2" v-if="type === 'register'">
        <button class="w-full flex items-center gap-2 p-2 rounded-2xl bg-input default-transition" :class="{
          '!bg-green-500/20': agreePolicy,
        }" @click="agreePolicy = !agreePolicy">
          <CheckIcon v-if="agreePolicy" class="flex-none size-7 text-green-400" />
          <div v-else class="flex-none size-5 m-1 rounded-full bg-zinc-300/10" />
          <p>{{ $t('login.policy') }}</p>
        </button>
        <a href="/policy" target="_blank" class="flex-none grid place-content-center p-2 rounded-full bg-input">
          <EllipsisHorizontalCircleIcon class="flex-none size-7 text-zinc-300" />
        </a>
      </div>

      <!-- login button -->
      <div class="w-full flex flex-col gap-2" v-if="type === 'login'">
        <button
          class="w-full flex items-center gap-2 p-2 rounded-2xl justify-center ring ring-white/50 hover:bg-white/90 hover:text-zinc-900 text-white"
          :disabled="email === '' || password === ''" @click="login">{{ $t('login.login') }}</button>

        <button class="w-full flex items-center gap-2 p-2 rounded-2xl justify-center text-cyan-500/50 hover:text-cyan-500"
          @click="type = 'register'">{{ $t('login.needToRegister') }}</button>
      </div>

      <!-- register button -->
      <div class="w-full flex flex-col gap-2" v-if="type === 'register'">
        <button
          class="w-full flex items-center gap-2 p-2 rounded-2xl justify-center ring ring-white/50 hover:bg-white/90 hover:text-zinc-900 text-white"
          :disabled="email === '' || password === '' || !agreeEula || !agreePolicy" @click="register">
          {{ $t('login.register') }}
        </button>

        <button class="w-full flex items-center gap-2 p-2 rounded-2xl justify-center text-cyan-500/50 hover:text-cyan-500"
          @click="type = 'login'">{{ $t('login.alreadyRegister') }}</button>
      </div>


      <hr class="my-4 w-full border border-zinc-700/50" />

      <span v-if="type === 'register' && (!agreeEula || !agreePolicy)" class="text-zinc-300">{{ $t('login.agreeNotify')
      }}</span>

      <button v-for="provider in oauthProviders" :key="provider.name"
        :disabled="type === 'register' && (!agreeEula || !agreePolicy)"
        class="w-full flex items-center gap-2 p-2 rounded-2xl" :class="provider.class" @click="oauth(provider.name)">
        <img :src="provider.icon" class="size-7 flex-none" />
        <span class="block w-full text-center"> Sign in with {{ provider.name }} </span>
      </button>
    </div>
  </section>
</template>

<script setup>
import { EnvelopeIcon, LockClosedIcon, ChatBubbleOvalLeftEllipsisIcon, EllipsisHorizontalCircleIcon, CheckIcon } from '@heroicons/vue/24/outline'
import metaData from '@/config/manifest.json'

const { t } = useNuxtApp().$i18n

useSeoMeta({
  title: `${t('login.title')} | ${metaData.name}`,
  ogTitle: `${t('login.title')} | ${metaData.name}`,
  description: `${t('login.title')} | ${metaData.description}`,
  ogDescription: `${t('login.title')} | ${metaData.description}`,
  ogImage: 'https://jasonxddd.me:9000/bottleneko/bottleneko-v2.png',
  twitterCard: 'summary_large_image'
})

const globalStore = useGlobalStore()
const { openPane, getUser } = globalStore
const { getMyDecks } = useDeckStore()
const { getPreference } = usePreferenceStore()

const { messageError } = useSwal()

const loading = ref(false)
const type = ref('login')
const agreeEula = ref(false)
const agreePolicy = ref(false)

const oauthProviders = ref([
  { name: 'Google', icon: '/images/login/google.svg?inline', class: 'shadow border bg-white' },
  { name: 'Apple', icon: '/images/login/apple.svg?inline', class: 'bg-black text-white' },
  // { name: 'Facebook', icon: '/images/login/facebook.svg', class: 'bg-[#3a5999] text-white' },
  { name: 'Twitter', icon: '/images/login/twitter.svg?inline', class: 'bg-[#06abed] text-white' },
])

const password = ref('')
const email = ref('')
const { loginEmail, loginOauth, registerEmail, getRedirectUser } = useFirebase()
const { sleep } = useTool()
const router = useRouter()

const oauth = async (type) => {
  try {
    window.localStorage.setItem('provider', type)
    const res = await loginOauth(type)
    if (res.status === 'error') {
      throw new Error(res.message)
    }

    await afterLogin()
  } catch (e) {
    await messageError(t('ajax.fail'), e)
    console.error(e)
  }
}

const login = async () => {
  try {
    const res = await loginEmail(email.value, password.value)
    if (res.status === 'error') {
      throw new Error(res.message)
    }

    await afterLogin()
  } catch (e) {
    await messageError(t('ajax.fail'), e)
    console.error(e)
  }
}

const register = async () => {
  try {
    const res = await registerEmail(email.value, password.value)
    if (res.status === 'error') {
      throw new Error(res.message)
    }

    await afterLogin()
  } catch (e) {
    await messageError(t('ajax.fail'), e)
    console.error(e)
  }
}

const afterLogin = async () => {
  loading.value = true
  try {
    await sleep(1000)
    await getUser()
    await Promise.all([
      getMyDecks(),
      getPreference(),
    ])
    loading.value = false
    router.push('/user')
  } catch (e) {
    console.error(e)
    loading.value = false
  }
}

onMounted(async () => {
  const provider = window.localStorage.getItem('provider')
  if (provider) {
    const user = await getRedirectUser(provider)
    window.localStorage.removeItem('provider')

    if (user) {
      await afterLogin()
    }
  }
})


</script>

<style lang="sass" scoped>
.bg-input
  // @apply bg-zinc-100 hover:bg-zinc-200 focus-within:bg-zinc-200 active:bg-zinc-200
  @apply bg-zinc-800 hover:bg-zinc-700 focus-within:bg-zinc-700 active:bg-zinc-700 text-white
</style>
